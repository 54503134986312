import React,{ useState, useEffect } from 'react';
import { withTheme, withStyles, Typography,Button, Modal, Paper, CircularProgress, } from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalAlert from '../ModalAlert/ModalAlert';
import axiosCerebrum from '../../../axios-cerebrum';
import SimpleResultItem from '../SearchResults/SimpleResultItem';

const styles = theme => ({
  modalTitle: {
    fontSize: 20,
    color:theme.palette.header.main,
  },
  modalSubTitle: {
    fontSize:13.75,
    marginBottom: 8,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.main
  },
})

function AddCartConfirmModal(props) {

  const {
    classes,
    theme,
    modalOpen,
    setModalOpen,
    processAddToCart,
    onModalClose
  } = props;

  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const clearCart = () => {
    return axiosCerebrum
      .delete(
        `/api/me/cart`
      )
  }

  useEffect(()=>{
    if(!modalOpen){
      setLoading(false)
      setAlertOpen(false)
    }
  },[modalOpen])

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{outline:'none',width:'max-content',margin:'25vh auto 0 auto'}}>
        <Paper style={{width:480,boxSizing:'border-box',padding:24,paddingBottom:40, background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div style={{display:'flex',alignItems:'flex-start',justifyContent:"space-between",marginBottom:0}}>
            <Typography className={classes.modalTitle}>Add to Cart</Typography>
            <Button 
              onClick={()=>{
                setModalOpen(false)
                onModalClose?.()
              }}
              color='primary' 
              variant='outlined'
            >
              CANCEL
            </Button>
          </div>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:24}}>Your cart has items in it. Select an option</Typography>
          {
            !loading && 
            [
              {
                icon:'add_cart',
                text:'Add to cart with existing item(s)',
                testId:'add-cart-option',
                onClick:()=>{
                  setLoading(true)
                  processAddToCart(true)
                }
              },
              {
                icon:'remove_cart',
                text:'Clear cart and add new item(s)',
                testId:'clear-cart-option',
                onClick:()=>{
                  setLoading(true)
                  clearCart()
                    .then(()=>{
                      processAddToCart()
                    })
                    .catch(error=>{
                      console.log(error)
                      setAlertMessage('Error occurred clearing cart')
                      setAlertOpen(true)
                      setLoading(false)
                    })
                }
              }
            ].map(el=>(
              <SimpleResultItem
                title={el.text}
                label={el.icon}
                data-test-id={el.testId}
                titleColour={theme.palette.primaryText.main}
                iconColour={theme.palette.primaryText.light}
                item={el}
                hideRight
                showUnderline
                onClick={el.onClick}
              />  
            ))
          }
          {
            loading && 
            <div style={{display:'flex',justifyContent:'center',marginBottom:24}}>
              <CircularProgress color='secondary'/>
            </div>
          }
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </Modal>
  )
}

AddCartConfirmModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddCartConfirmModal));